<script>
export default {
  data() {
    return {
    };
  },
  props: {
    title: String,
    short: String,
    listeners: Number,
    urlAvatar: String,
    isServerSide: Boolean,
    closeButton: Boolean
  },
  components: {
  },
  computed: {
    getFirstLetter() {
      if(this.title)
        return this.title[0].toUpperCase();
      return '';
    },
    getEpisodeUrl() {
      if(this.isServerSide)
        return "/episode/" + this.$route.params.id;
      else
        return '#';
    }
  }
};
</script>

<template>
  <div class="pb-3 user-chat-topbar">
    <div class="row align-items-center">
      <div class="col-sm-4 col-8">
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <div class="d-flex align-items-center">

              <div class="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">

                <a :href="getEpisodeUrl" target="_blank">
                  <div class="avatar-xs flex-shrink-0" v-if="!urlAvatar">
                    <div class="avatar-title rounded-circle">{{ getFirstLetter }}</div>
                  </div>
                  <img :src="urlAvatar" class="rounded-circle avatar-xs" :alt="short" v-if="urlAvatar" />
                </a>

              </div>
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="text-truncate mb-0 fs-16">
                  <a class="text-reset username" :href="getEpisodeUrl" target="_blank">{{ title }}</a>
                </h5>
                <p class="text-truncate text-muted fs-14 mb-0 userStatus">
                  <small>{{ short }}</small>
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-8 col-4">
        <ul class="list-inline user-chat-nav text-end mb-0">

          <li class="list-inline-item m-0">
            <div class="dropdown">
              <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="ri-more-fill"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item" :href="getEpisodeUrl" target="_blank">
                  <i class="ri-eye-fill text-muted me-2 align-bottom"></i> View
                </a>
                <a href="javascript:void(0);" class="dropdown-item" target="_blank" data-bs-dismiss="modal" v-if="isServerSide && closeButton">
                  <i class="ri-close-line text-muted me-2 align-bottom"></i> Close
                </a>
              </div>
            </div>
          </li>
          <li class="list-inline-item m-0">
            <button class="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-dismiss="modal" v-if="isServerSide && closeButton">
              <i class="ri-close-line text-muted align-bottom"></i>
            </button>
          </li>

        </ul>
      </div>
    </div>
  </div>
</template>
