<script>
export default {
  data() {
    return {
      sum: 0,
      count: 0,
    };
  },
  props: {
    session: String,
    episode: String,
  },
  components: {
  },
  methods: {
    updateDonate(donations) {
      this.sum = donations.sum;
      this.count = donations.count;
    },
    
  },
};
</script>

<template>
  <div class="card shadow-none m-0">
    <div class="card-header ps-0 pt-0">
      <h2 class="card-title mb-0 flex-grow-1">Donate</h2>
    </div>
    <div class="card-body ps-lg-0 pe-0">

      <div class="d-flex align-items-center">
        <div class="avatar-sm flex-shrink-0"><span class="avatar-title bg-light text-primary rounded-circle fs-3">
            <i class="ri-money-dollar-circle-fill align-middle"></i></span>
        </div>
        <div class="flex-grow-1 ms-3">
          <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">Total donated ({{ count }})</p>
          <h4 class="mb-0"> ₴ <span class="counter-value">{{ sum }}</span></h4>
        </div>
        
        <router-link :to="'/payments/donation/' + this.episode + '/' + this.session" class="btn btn-primary" target="_blank">
          Donation
        </router-link>
      </div>

    </div>
  </div>
</template>
