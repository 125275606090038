<script>
export default {
  data() {
    return {
      sessionProfiles: [],
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
    videoContainerVisible: {
      default: true,
      type: Boolean
    },
  },
  components: {
  },
  methods: {
    updateProfiles(profiles) {
      this.sessionProfiles = profiles;
    },
    currentSpeaker(list) {
      if(this.sessionProfiles && list) {
        this.sessionProfiles.forEach((p) => {
          p.profile.speaker = false;
          list.forEach((l) => {
            if(p.profile.id === l) {
              p.profile.speaker = true;
            }
          });
        });
      }
    }
  }
};
</script>

<template>
<div class="row videocontainer" v-if="this.sessionProfiles.length > 0">
  <div class="col" v-for="data of sessionProfiles" :key="data.profile.id">
    <div class="card">
      <div class="card-body p-0">
        <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <router-link :to="{ name: 'profile', params: { login: data.profile.login } }" target="_blank">
                <div class="avatar-sm flex-shrink-0 me-1" v-if="!data.profile.urlAvatar">
                  <div class="avatar-title rounded-circle" :class="{ 'avatar-talking' : data.profile.speaker }">{{ data.profile.login[0].toUpperCase() }}</div>
                </div>
                <div :class="{ 'avatar-talking' : data.profile.speaker }" v-if="data.profile.urlAvatar">
                  <img :src="data.profile.urlAvatar" alt="" class="avatar-sm rounded-circle" :class="{ 'border border-primary' : data.profile.speaker }">
                </div>
              </router-link>
            </div>
            <div class="flex-grow-1 ms-2">
              <h5 class="card-title mb-1">
                <router-link :to="{ name: 'profile', params: { login: data.profile.login } }" class="text-body" target="_blank">
                {{ data.profile.login }} 
                </router-link>
                &nbsp;<span class="badge bg-primary me-2 d-none d-lg-inline" v-if="data.isModerator">moderator</span>
                <i class="ri-mic-off-fill live-profile-small-icon me-1" v-if="!data.micState"></i>
                <i class="ri-camera-off-fill live-profile-small-icon" v-if="!data.camState"></i>
              </h5>
              <p class="text-muted mb-0">{{ data.profile.displayName }}</p>
            </div>
        </div>
        <div class="mt-3" v-if="videoContainerVisible">
          <div class="webrtc-video-player">
            <video :id="'videoPlayer_' + data.connectionId" playsinline></video>
          </div>
          <audio :id="'audioPlayer_' + data.connectionId" class="w-100 webrtc-audio-player"></audio>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
