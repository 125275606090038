const userConnections = {};
const connectionToUser = {};

function addMapping(connectionId, userId) {
    if (!userConnections[userId]) {
        userConnections[userId] = new Set();
    }

    if (!connectionToUser[connectionId]) {
        userConnections[userId].add(connectionId);
        connectionToUser[connectionId] = userId;
        console.log('MappingManager. Added mapping:', { connectionToUser, userConnections });
    }
}

function removeMappingByConnectionId(connectionId) {
    const userId = connectionToUser[connectionId];

    if (userId) {
        userConnections[userId].delete(connectionId);
        delete connectionToUser[connectionId];

        if (userConnections[userId].size === 0) {
            delete userConnections[userId];
        }

        console.log('MappingManager. Removed mapping by connectionId:', { connectionToUser, userConnections });
    }
}

function removeMappingByUserId(userId) {
    if (userConnections[userId]) {
        for (const connectionId of userConnections[userId]) {
            delete connectionToUser[connectionId];
        }
        delete userConnections[userId];

        console.log('MappingManager. Removed mapping by userId:', { connectionToUser, userConnections });
    }
}

function getUserIdByConnectionId(connectionId) {
    return connectionToUser[connectionId] || null;
}

function getConnectionIdsByUserId(userId) {
    return userConnections[userId] ? Array.from(userConnections[userId]) : [];
}

function clearMappings() {
    for (const connectionId in connectionToUser) {
        delete connectionToUser[connectionId];
    }

    for (const userId in userConnections) {
        delete userConnections[userId];
    }

    console.log('MappingManager. Cleared all mappings:', { connectionToUser, userConnections });
}

function hasConnectionId(connectionId) {
    return connectionToUser[connectionId];
}

function hasUserId(userId) {
    return userConnections[userId];
}

export const mappingManager = {
    addMapping,
    removeMappingByConnectionId,
    removeMappingByUserId,
    getUserIdByConnectionId,
    getConnectionIdsByUserId,
    clearMappings,
    hasConnectionId,
    hasUserId,
};

export default mappingManager;