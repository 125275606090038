<script>
export default {
  emits: ["sendMessage"],
  data() {
    return {
      connected: false,
      form: {
        message: "",
      },
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
    donateAllows: Boolean,
    session: String,
    episode: String
  },
  components: {
  },
  methods: {
    formSubmit() { 
      if(this.form.message !== '') {
        this.$emit('sendMessage', this.form);
        this.form.message = '';
        this.$refs.txtMessage.focus();
      }
    }
  },
  computed: {
    getProfileId: function() {
      return localStorage.getItem('user.id');
    },
  }
};
</script>

<template>
  <div class="chat-input-section pt-3" v-if="getProfileId">
    <form @submit.prevent="formSubmit">
      <div class="row g-1 align-items-center">
        <div class="col">
          <input ref="txtMessage" type="text" v-model="form.message" :disabled="!connected" class="form-control chat-input bg-light border-light" maxlength="256" :class="{ disabled: !this.connected }" placeholder="Enter Message..." />
        </div>
        <div class="col-auto ms-2">
          <b-button-group role="group" aria-label="Basic example">
            <button type="submit" class="btn btn-primary chat-send rounded-pill" :disabled="!connected" :class="{ disabled: !this.connected }">
              Send
            </button>
            <a class="btn btn-warning chat-send rounded-pill ms-2" v-if="donateAllows" :disabled="!connected" :href="'/payments/donation/' + this.episode + '/' + this.session" target="_blank">
              Donate
            </a>
          </b-button-group>
        </div>
      </div>
    </form>
  </div>
  <div class="chat-input-section pt-3" v-if="!getProfileId">
    <small>Only authorized users can post messages in the chat: 
    <router-link class="text-primary" to="/login">
      login
    </router-link>
    .</small>
  </div>
</template>
