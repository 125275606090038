function setupConnection(wsconn, component) {

    wsconn.on('Log', (message) => {
        component.sendSystemMessageToChat(message, false);
    });

    wsconn.on('GetInviteLink', (link) => {
        const inviteWithHash = link.linkInvite + '?h=' + link.hash;
        const inviteLink = '<a class="text-decoration-underline text-primary" href="' + inviteWithHash + '">invite</a>';
        const buttonCopy = '<a role="button" data-link="' + inviteWithHash + '"><i class="ri-file-copy-line"></i></a>';
        const message = 'To invite guests to this room, forward this link: ' + inviteLink + ' ' + buttonCopy + '.';
        component.sendSystemMessageToChat(message, true);
        component.idSessionGroup = link.id;
    });

    wsconn.on('GetStreamLink', (link) => {
        const streamUrl = '<a class="text-decoration-underline text-primary" href="' + link.link + '" target=_blank>stream</a>';
        const buttonCopy = '<a role="button" data-link="' + link.link + '"><i class="ri-file-copy-line"></i></a>';
        const message = 'The public stream has been set up at the link: ' + streamUrl + ' ' + buttonCopy;
        component.sendSystemMessageToChat(message, true);
    });

    wsconn.on('GetGuestLink', (link) => {
        const inviteWithHash = link.linkInvite + '?h=' + link.hash;
        const inviteUrl = '<a class="text-decoration-underline text-primary" href="' + inviteWithHash + '">connect</a>';
        const message = 'You\'ve been invited to participate in the live room: ' + inviteUrl + '.';
        component.sendSystemMessageToChat(message, true);
    });

    wsconn.on('GetProfiles', (profiles) => {
        console.log("New list of profiles", profiles);
        component.moderatorConnectionId = profiles.state.moderatorConnectionId;
        component.listenersCounter = profiles.state.guestsCount;
        component.speakersCounter = profiles.list.length;
        component.updateProfiles(profiles.list);
        component.$refs.pnlProfiles.updateProfiles(profiles.list);
    });

    wsconn.on('GetTracksOrder', (tracks) => {
        console.log("New tracks order: ", tracks);
        tracks.tracks.forEach((t) => {
            component.setSourceOfTrackByMid(t.mid, t.connectionId);
        });
        component.tracksOrder = tracks.tracks;
    });

    wsconn.on('NewMessage', (profile, message) => {
        AddMessageToChat(component, profile, message);
    });

    wsconn.on('NewSystemMessage', (message) => {
        console.log('New system message');
        component.sendSystemMessageToChat(message, true);
    });

    wsconn.on('HistoryMessages', (messages) => {
        console.log('History messages', messages);
        messages.forEach((message) => AddMessageToChat(component, message.profile, message.message));
    });

    wsconn.on('ReceiveSignal', (profile, body) => {
      console.log('WebRTC: called newSignal');

      var signal = JSON.parse(body);
      var connection = component.getConnection(profile.connectionId);

      // Route signal based on type
      if (signal.sdp) {
          console.log('WebRTC: sdp signal');
          component.receivedSdpSignal(connection, profile.connectionId, signal.sdp, component);
      } else if (signal.candidate) {
          console.log('WebRTC: candidate signal:', signal.candidate);
          component.receivedCandidateSignal(connection, profile.connectionId, signal.candidate);
      } else {
          console.log('WebRTC: adding null candidate');
          connection.addIceCandidate(null)
          .then(() => console.log("WebRTC: added null candidate successfully"))
          .catch(() => console.warn("WebRTC: cannot add null candidate"));
      }
    });

    wsconn.on('GetState', (state) => {
        console.log("New state", state);
        component.listenersCounter = state.guestsCount;
        component.streamConfigAllowDonations = state.allowDonations;
        component.streamConfigAllowChat = state.allowChat;
        component.$nextTick(() => {
            if(component.$refs.pnlDonations) {
                component.$refs.pnlDonations.updateDonate(state.donations);
            }
            if(component.$refs.pnlSend) {
                component.$refs.pnlSend.connected = state.allowChat;
            }
            if(component.$refs.pnlMessages) {
                component.$refs.pnlMessages.pinned = state.pinnedMessage;
            }

            //Video player for my WebCamera
            if(component.myConnectionId && component.myVideoTrack) {
                console.log("Set my camera view: ", component.myConnectionId);
                component.setSourceOfTrack(component.myVideoTrack, component.myConnectionId);
            }
        });
    });

    wsconn.on('ModeratorLeft', (message) => {
        console.log("Moderator has left.");
        if(!component.isServerSide) {
            component.sendSystemMessageToChat(message, false);
            component.hangupCall();
        }
    });

    wsconn.on('CallReady', () => {
        console.log('Ready to call to moderatorId', component.moderatorConnectionId);
        component.initiateOffer(component.moderatorConnectionId);
    });

    wsconn.on('StreamStop', (message) => {
        if(message) {
            component.sendSystemMessageToChat(message, true);
        }
        console.log("Stream stop");
        component.stopStream();
        component.streamActive = false;
    });

    wsconn.on('ActiveSpeakers', (list) => {
        if(component.debugMode)
            console.log("Speakers: ", list);
        component.$refs.pnlProfiles.currentSpeaker(list);
        component.currentSpeaker(list);
    });

    wsconn.on('NewDonation', (donate) => {
        console.log('New donation', donate);
        var time = new Date().toLocaleTimeString();
        var message = '₴ <b>' + donate.price + '</b>';
        if(donate.message && donate.message.length > 0)
            message += ': ' + donate.message;
        var m = {
            align: 'right',
            message: message,
            time: time,
            isGuest: false,
            isDonation: true,
        }
        if(!donate.isAnonymous && donate.profile) {
            var profile = donate.profile.profile;
            m.name = profile.displayName;
            m.image = profile.urlAvatar;
            m.login = profile.login;
            m.profileId = profile.id;
        }
        component.$refs.pnlMessages.addMessage(m);
        if(component.$refs.pnlDonations) {
            component.$refs.pnlDonations.updateDonate(donate.donations);
        }
    });

    wsconn.on('AllowChat', (enabled) => {
        console.log('AllowChat: ', enabled);
        component.streamConfigAllowChat = enabled;
        if(component.$refs.pnlSend)
            component.$refs.pnlSend.connected = enabled;
    });

    wsconn.on('AllowDonations', (enabled) => {
        console.log('AllowDonations: ', enabled);
        component.streamConfigAllowDonations = enabled;
    });

    wsconn.on('Logout', () => {
        console.log('Force logout');
        component.hangupCall();
    });

    wsconn.onclose(e => {
        component.visibleModeratorsToolset = false;
        component.$refs.pnlSend.connected = false;
        if(component.isServerSide)
            component.stopSession();
        else
            component.hangupCall();
    
        if (e) {
            console.log("SignalR: closed with error.", e);
        }
        else {
            console.log("Disconnected");
        }
    });

}

/* eslint-disable no-useless-escape */
function linkify(text) {
    var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function(url) {
        return '<a target=_blank class="text-decoration-underline text-primary" href="' + url + '">' + url + '</a>';
    });
}

function AddMessageToChat(component, profile, message) {
    var time = new Date().toLocaleTimeString();
    var align = profile.isAnonymous
        ? false
        : localStorage.getItem('user.id') == profile.profile.id;
    
    var msg;
    if(message.type === 4 && message.donate !== null) {
        msg = '₴ <b>' + message.donate.price + '</b>';
        if(message.value && message.value.length > 0)
            msg += ': ' + message.value;
    }
    else
        msg = message.value;

    msg = linkify(msg);

    var m = {
      id: message.id,
      align: align ? 'right' : 'left',
      name: profile.isAnonymous ? '' : profile.profile.displayName,
      image: profile.isAnonymous ? '' : profile.profile.urlAvatar,
      login: profile.isAnonymous ? '' : profile.profile.login,
      profileId: profile.isAnonymous ? '' : profile.profile.id,
      message: msg,
      time: time,
      isGuest: message.type === 3, //Services.Live.Enums.MessageType.GUEST==3
      isDonation: message.type === 4, //Services.Live.Enums.MessageType.DONATION==4
    }
    component.$refs.pnlMessages.addMessage(m);
}

export { setupConnection, linkify }